import "../../assets/page.scss";
import "./home.scss";

import React, {useEffect, useRef, useState} from 'react';
import Footer from "../../components/ui/footer.jsx";
import SupercarScene from "../../components/scenes/supercarScene/supercarScene";
import StoryLine from "./storyLine/storyLine";
import {
    HeartIcon,
    CodeIcon,
    AngleIcon,
    LightningBoltIcon,
    EnvelopeClosedIcon,
    LinkedInLogoIcon, HeartFilledIcon
} from "@radix-ui/react-icons";
import List from "./list/list";
import Gallery from "./gallery/gallery"
import Experience from "../../components/ui/strings/experience";
import parallaxAnimation from "../../components/effects/parallaxAnimation";
import "../../components/effects/frostedGlassAnimation/frostedGlass.scss";
import frostedGlassAnimation from "../../components/effects/frostedGlassAnimation/frostedGlassAnimation";
import {Calendar, MessageCircle} from "lucide-react";
import SupercarWireframeScene from "../../components/scenes/supercarScene/supercarWireframeScene";
import {gsap} from "gsap";
import LineHeart from "../../components/graphics/lineArt/lineHeart";

const title = "Patrick Dajos";

/**
 * Animates a text reveal by iterating through characters, displaying random gibberish before revealing the actual text.
 * Utilizes a setInterval to create the animation effect.
 * @param text {string} The final text to display after the animation.
 * @param setFunc {Function} The function to set the text state during the animation.
 */
function renderTitleAnimation(text, setFunc) {
    let intervalId   = null;
    const targetText = text;
    const startText  = Array.from(targetText, () => '-');
    let currentIndex = -1;
    setFunc(startText);

    const loadInterval = setInterval(() => {
        const allLoaded = Object.values(window.isLoading).every(status => !status);
        if (allLoaded) {
            setTimeout(() => setInterval(animateText, 80), 500);
            clearInterval(loadInterval);
        }
    }, 100);

    const animateText = () => {
        const randomChar = () => String.fromCharCode(33 + Math.random() * (127 - 33));
        let gibberish = Array.from({length: targetText.length})
            .map(() => randomChar())
            .join('');

        if (currentIndex < targetText.length) {
            gibberish = targetText.slice(0, currentIndex) + gibberish.slice(currentIndex);
            setFunc(gibberish);
            currentIndex++;
        } else {
            setFunc(targetText);
            clearInterval(intervalId);
        }
    }
}

/**
 * Renders the about background animation with GSAP, expanding width based on scroll position.
 * @param bgContainer {Element} The container element for the background animation.
 * @param setAniProgress {Function} Callback function to update animation progress.
 * @returns {GSAPAnimation[]} Array of GSAP animations and ScrollTriggers for background expansion.
 */
function renderAboutAnimation(bgContainer, setAniProgress) {
    const aniRefs = [];

    aniRefs.push(gsap.fromTo(bgContainer,
        {
            width: "0%"
        },
        {
            width: "100%",
            duration: 1,
            ease: "power1.inOut",
            scrollTrigger: {
                trigger: bgContainer,
                start: "top bottom",
                end: "bottom center+=40%",
                scrub: true,
                onUpdate: self => setAniProgress(self.progress)

            }
        }
    ));

    return aniRefs;
}

function View() {
    const [aboutAniProgress, setAboutAniProgress] = useState(0);
    const [text, setText]  = useState('');
    const parallaxBgRefs   = useRef([]);
    const frostedBgRefs    = useRef([]);
    const aboutBgRef       = useRef();
    parallaxBgRefs.current = [];
    let aniRefs            = [];

    useEffect(() => {
        renderTitleAnimation(title, setText);
        aniRefs = parallaxAnimation(parallaxBgRefs.current);
        aniRefs.push(...frostedGlassAnimation(frostedBgRefs.current));
        aniRefs.push(...renderAboutAnimation(aboutBgRef.current, setAboutAniProgress));

        return () => aniRefs.forEach(tl => tl.scrollTrigger.kill());
    }, []);

    const addToParallaxBgRefs = el => { if (el && !parallaxBgRefs.current.includes(el)) parallaxBgRefs.current.push(el); };
    const addToFrostedBgRefs = el => { if (el && !frostedBgRefs.current.includes(el)) frostedBgRefs.current.push(el); };

    return (
        <div className="home-root">
            <h1 className="hide">{process.env.REACT_APP_NAME} Patrick Dajos, Software Developer Engineer, Frontend
                Backend, Designer, Senior, Team-Lead</h1>

            <div id="introduction" className="introduction">
                <div ref={addToParallaxBgRefs} className="background"/>
                <div className="content-box pb-64">
                    <div className="line"/>
                    <h2 className="text--xl font-racama">{text}</h2>
                    <h5 className="text--s">Fullstack Developer & Founder</h5>
                </div>
            </div>

            <div id="about" className="about relative">
                <div className="line"/>
                <div className="content-box">
                    <div ref={aboutBgRef} className="background">
                        <div className="background-container">
                            {window.innerWidth > 580 && <SupercarScene progress={aboutAniProgress}/>}
                        </div>
                    </div>
                    {window.innerWidth > 580 && <SupercarWireframeScene progress={aboutAniProgress}/>}

                    <div className="about-text">
                        <h2 className="text--s">About me</h2>
                        <h3 className="text--l">
                            A cracked software engineer, with skills not replaced by AI in app- and web-development,
                            harnessing creativity
                            and downright insane determination.
                        </h3>
                        <p className="mt-4">
                            One of my hobbies are Motorsports, sports cars and motorcycles inspire and motivate me.
                        </p>
                        <p className="text-util-500 text--s">
                            Making good shit, when the paycheck is equally good.
                        </p>
                    </div>
                </div>
            </div>

            <div className="what-do relative">
                <div className="line"/>

                <div className="content-box">
                    <div className="about-text">
                        <h2 className="text--s text--center">What I do</h2>
                        <div className="spacer"/>
                        <div className="flex flex-wrap justify-center items-center md:mb-24 mb-16">
                            <div className="card-item lg:m-8 md:m-6 m-2">
                                <div className="card-content md:p-6 p-2">
                                    <AngleIcon width="2em" height="2em"/>
                                    <h3>Design</h3>
                                    <div className="spacer--s"/>
                                    <p className="text--s">
                                        I create strategies, themes and interfaces, from concept to implementation.
                                    </p>
                                </div>
                                <div className="card-light"/>
                            </div>
                            <div className="card-item lg:m-8 md:m-6 m-2">
                                <div className="card-content md:p-6 p-2">
                                    <CodeIcon width="2em" height="2em"/>
                                    <h3>Code</h3>
                                    <div className="spacer--s"/>
                                    <p className="text--s">
                                        I engineer effective features and systems from scratch.
                                    </p>
                                </div>
                                <div className="card-light"/>
                            </div>
                            <div className="card-item lg:m-8 md:m-6 m-2">
                                <div className="card-content md:p-6 p-2">
                                    <HeartIcon width="2em" height="2em"/>
                                    <h3>Mentor & Lead</h3>
                                    <div className="spacer--s"/>
                                    <p className="text--s">
                                        I genuinely care about people and love helping out fellow devs.
                                    </p>
                                </div>
                                <div className="card-light"/>
                            </div>
                            <div className="card-item lg:m-8 md:m-6 m-2">
                                <div className="card-content md:p-6 p-2">
                                    <LightningBoltIcon width="2em" height="2em"/>
                                    <h3>Fearless Research</h3>
                                    <div className="spacer--s"/>
                                    <p className="text--s">
                                        I embrace challenges and dig deep into complex topics.
                                    </p>
                                </div>
                                <div className="card-light"/>
                            </div>
                        </div>

                        <Experience/>
                    </div>
                </div>
            </div>

            <List/>

            <Gallery/>

            <StoryLine/>

            <div className="testimonials">
                <LineHeart/>
                <>
                    <h2 className="text--xl">What <span className="rainbow-text">They</span> Say...</h2>
                    <p className="text--s text-util-500">My clients and teammates are amazed with me.</p>
                    <div
                        className="text--s md:pt-64 pt-28 grid xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-4">
                        <div className="md:pt-16 pt-8">
                            <h3>Lullemi</h3>
                            <p className="text--xs text-util-500">Patryk's Cat</p>
                            <div className="spacer--s"/>
                            <p>I write all his code -_-</p>
                            <div className="spacer--s"/>
                            <img alt="My cat coding for me." src="/assets/img/software_developer_keyboardcat.gif"
                                 width="50%"/>
                        </div>
                        <div className="md:pt-16 pt-8">
                            <h3>Etienne Fakler</h3>
                            <p className="text--xs text-util-500">Makler Service AG</p>
                            <div className="spacer--s"/>
                            <p>Our very individual request for a web-app was implemented immediately and efficiently.
                                The results exceeded our expectations by far.</p>
                        </div>
                        <div className="md:pt-16 pt-8">
                            <h3>Rolf Bosch</h3>
                            <p className="text--xs text-util-500">Eximie GmbH</p>
                            <div className="spacer--s"/>
                            <p>I am impressed by his expertise, professionalism and ability to efficiently implement new
                                features.</p>
                        </div>
                        <div className="md:pt-16 pt-8">
                            <h3>M*** S***</h3>
                            <p className="text--xs text-util-500">Goldkontor Cottbus</p>
                            <div className="spacer--s"/>
                            <p>I was very impressed by the expertise and professionalism Patryk brings. He brings
                                next-gen solutions to my vision.</p>
                        </div>
                    </div>
                </>
            </div>

            <div className="motto">
                <div ref={addToFrostedBgRefs} className="frosted-glass"/>
                <div ref={addToParallaxBgRefs} className="background"/>
                <div className="center">
                    <h2 className="text--s text--center">My Motto</h2>
                    <h3 className="text--l text--center">Do more, move fast, talk less.</h3>
                </div>
            </div>

            <div id="contact" className="contact">
                <div className="line"/>
                <div className="content-box text--s">
                    <div className="line"/>
                    <p className="text--s">Made with <HeartFilledIcon className="inline" width="1em" height="1em"/> in
                        2024.</p>
                    <div className="spacer--l"/>
                    <div className="grid xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-4">
                        <div>
                            <h2>Contact Me</h2>
                            <h3 className="text--xs text-util-500">I might even show you this code...</h3>
                            <div className="spacer"/>
                            <p><EnvelopeClosedIcon className="inline" width="1em"
                                                   height="1em"/> patryk.dajos@avalonia.space
                            </p>
                            <p><LinkedInLogoIcon className="inline" width="1em" height="1em"/> <a
                                href="https://www.linkedin.com/in/patryk-dajos/" target="_blank">Patryk Dajos 🐉</a></p>
                            <p><Calendar className="inline" width="1em" height="1em"/> <a
                                href="https://calendly.com/patryk-dajos/60min" target="_blank">Calendly</a></p>
                            <p><MessageCircle className="inline" width="1em" height="1em"/> <a
                                href="https://t.me/avalonia_space" target="_blank">Telegram</a></p>
                        </div>
                        <div>
                            <h2>{"</>"}</h2>
                            <h3 className="text--xs text-util-500">Built from scratch with...</h3>
                            <div className="spacer"/>
                            <p>React 18</p>
                            <p>GSAP 3</p>
                            <p>Tailwind 3</p>
                            <p>Trois 0.16</p>
                        </div>
                        <div>
                            <h2>~</h2>
                            <h3 className="text--xs text-util-500">Possible with...</h3>
                            <div className="spacer"/>
                            <p>57+ cups of coffee</p>
                            <p>Endless inspiration</p>
                            <p>Laser sharp focus</p>
                            <p><a href="https://www.instagram.com/hobbyfotolausitz/"
                                  target="_blank">@Hobbyfotolausitz</a> photography</p>
                        </div>
                    </div>
                </div>
            </div>

            <Footer/>
        </div>
    );
}

export default View;
